import { createApp } from 'vue';

//引入element-plus
import elementPlus from 'element-plus';
import 'element-plus/dist/index.css';

//引入vuex
import loginStore from '@/store/mms/login/loginStore.js';

//引入根组件
import LoginApp from '@/components/mms/login/LoginApp.vue';

//引入全局通用css
import '@/assets/css/theme.css';

//引入login.css
import '@/assets/css/login/login.css';

const app = createApp(LoginApp);
app.use(elementPlus);
app.use(loginStore);
app.mount('#app');
