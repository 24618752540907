import { createStore } from 'vuex';

const state = {
    isShowAlert: false,
    alertMessage: '消息提示'
}

const actions = {
}

const mutations = {
    ChangeIsShowAlert(state, value) {
        state.isShowAlert = value;
    },
    ChangeAlertMessage(state, value) {
        state.alertMessage = value;
    }
}

//暴露
export default createStore({
    actions,
    mutations,
    state
});