<template>
    <div class="company-info">
        <h1>中食数字</h1>
        <hr/>
        <h2>全品类链路多元化服务平台</h2>
        <ul>
            <li class="mb-22">诚信为本，公正交易，创新务实，赋能企业，共筑富强</li>
            <li class="mb-22">创造数字交易环境，坚持特惠经营理念，为企业去库存提供有效解决方案</li>
            <li class="mb-22">聚焦数字交易，通过新质生产力创新，构建领先交易平台，为行业高质量发展指明方向</li>
            <li>不忘初心，创新进取，服务为本，诚信经营</li>
        </ul>
    </div>
</template>

<script setup>
    
</script>

<style scoped>
    .company-info {
        float: left;
        position: absolute;
        left: 0;
        width: 600px;
        letter-spacing: 3px;
        padding: 39px 0 0 0;
    }
    h1 {
        background-image: url('@/assets/image/login-logo.png');
        background-size: 51px 33px;
        background-repeat: no-repeat;
        line-height: 33px;
        font-size: 33px;
        padding-left: 61px;
        margin-bottom: 35px;
        font-weight: 400;
    }
    hr {
        color: #D7D7D7;
        opacity: 1;
        margin-bottom: 55px;
    }
    h2 {
        color: #1890FF;
        font-size: 32px;
        margin-bottom: 35px;
        font-weight: 400;
    }
    ul {
        padding-left: 0;
        margin: 0;
    }
    li {
        font-size: 14px;
        letter-spacing: normal;
        list-style: none;
    }
</style>
  