/**
 * 自动计算网页显示区的尺寸
 * 让页面元素始终保持占满页面
 */
function autoCalculateWindowSize() {
    //将尺寸数据设为变量，以便css中使用
    document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
    document.documentElement.style.setProperty('--window-width', window.innerWidth + 'px');
}
autoCalculateWindowSize();

//页面发生变化时自动重新计算
window.addEventListener('resize', () => {
    autoCalculateWindowSize();
});

export default autoCalculateWindowSize;