<template>
    <footer>
        <span>©版权所有：中食产业科技（广东）有限公司</span>
        <a target="_blank" href="https://www.miit.gov.cn/">粤ICP备2024307469号-1</a>
    </footer>
</template>

<script setup>
   
</script>

<style scoped>
    footer {
        position: absolute;
        left: calc(50% - 119.76px);
        bottom: 0;
        height: 78px;
    }
    span,a {
        display: block;
        color: #D7D7D7;
        font-size: 12px;
        opacity: 0.4;
        text-align: center;
    }
    span {
        margin-bottom: 16px;
    }
    a:hover {
        color: #fff;
        font-weight: normal;
    }
</style>