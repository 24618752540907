<template>
    <el-alert v-show="isShowAlert" :title="alertMessage" type="error" @close="store.commit('ChangeIsShowAlert', false)" show-icon />
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';

    //获取vuex
    const store = useStore();

    //从vuex获取参数
    const isShowAlert = computed(() => {return store.state.isShowAlert});
    const alertMessage = computed(() => {return store.state.alertMessage});
</script>

<style scoped>
    .el-alert {
        position: absolute;
        top: 5rem;
        left: calc(50% - 11.125rem);
        max-width: 356px;
        width: 22.25rem;
        max-height: 55px;
        height: 3.4375rem;
        color: #FF6D64;
        border-radius: 0.625rem;
        background-color: #f8d7da;
    }
    :deep(.el-alert__icon),
    :deep(.el-alert__title) {
        color: #FF6D64;
    }
    :deep(.el-alert__close-btn) {
        padding-top: 8px;
    }
    :deep(.el-alert__close-btn svg) {
        color: #000;
        opacity: 0.7;
    }
    :deep(.el-alert__close-btn svg:hover) {
        opacity: 1;
    }
</style>